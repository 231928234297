<template>
  <card>
    <template slot="header">
      <h3 class="mb-0">Subscription info</h3>
    </template>
    <div class="p-3">
      Billing Frequency: <span class="p-3">{{ planType === 'annual' ? 'Annual' : 'Monthly' }}</span>
    </div>
    <div class="p-3" v-if="!subscriptionInfo.cancelAtPeriodEnd">
      Next Billing Date: <span class="p-3">{{
        new Date(subscriptionInfo.endDate).toLocaleDateString(undefined,
          {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})
      }}</span>
    </div>
  </card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import {FETCH_PLANS_REQUEST} from '@/store/storeActions';

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'currentPlan',
      'planType',
      'subscriptionInfo',
    ]),
  },
  async mounted() {
  },
  methods: {
    ...mapActions([
      FETCH_PLANS_REQUEST,
    ]),
  },
};
</script>
