<template>
  <dashboard-page-wrapper title="Billing &amp; Plans">
    <b-row>
      <b-col md="6">
        <current-plan/>
        <top-up-texts v-if="isPayg"/>
        <subscription-info v-if="currentPlan"/>
        <card bodyClasses="px-0">
          <template slot="header">
            <h3 class="mb-0">Payment info</h3>
          </template>
          <div class="p-3 pl-4">
            <p v-if="!!creditCardLast4"
               @click="openModal" class="font-weight-bold">
              {{ creditCardBrand }}: **** **** **** {{ creditCardLast4 }}
            </p>
            <p v-else>No credit card</p>
            <div class="d-flex justify-content-end mt-3 mx-4">
              <base-button type="primary" @click="openModal">Edit credit card</base-button>
            </div>
          </div>
        </card>
      </b-col>
      <b-col md="6">
        <payment-history-table/>
      </b-col>
    </b-row>
    <edit-credit-card-modal ref="editCreditCardModal"/>
  </dashboard-page-wrapper>
</template>
<script>
import DashboardPageWrapper from '@/components/App/UI/DashboardPageWrapper.vue';
import {
  mapGetters,
  mapActions,
} from 'vuex';
import {FETCH_PAYMENTS_DATA_REQUEST} from '@/store/storeActions';
import PaymentHistoryTable from './PaymentHistoryTable.vue';
import EditCreditCardModal from './EditCreditCardModal.vue';
import CurrentPlan from './CurrentPlan.vue';
import {plansTypes} from '@/store/userConstants';
import TopUpTexts from './topUpTexts/TopUpTexts.vue';
import SubscriptionInfo from '@/views/Dashboard/billing/SubscriptionInfo';

export default {
  components: {
    SubscriptionInfo,
    DashboardPageWrapper,
    PaymentHistoryTable,
    EditCreditCardModal,
    CurrentPlan,
    TopUpTexts,
  },
  computed: {
    ...mapGetters([
      'userId',
      'invoicesLoaded',
      'creditCardBrand',
      'creditCardLoaded',
      'creditCardLast4',
      'currentPlan',
    ]),
    isPayg() {
      return this.currentPlan ? this.currentPlan.planType === plansTypes.PAYG : false;
    },
  },
  methods: {
    ...mapActions([
      FETCH_PAYMENTS_DATA_REQUEST,
    ]),
    openModal: function() {
      this.$refs.editCreditCardModal.displayToggle(true);
    },
  },
  async mounted() {
    if (this.userId && (!this.creditCardLoaded || !this.invoicesLoaded)) {
      this.FETCH_PAYMENTS_DATA_REQUEST();
    }
  },
};
</script>
