<template>
    <b-col cols="4" class="px-1 mb-2">
      <div
      class="app-border text-item p-2 d-flex flex-column justify-content-center align-items-center active-element"
      :class="{'selected': selected}"
      @click="$emit('select')"
      >
        <div class="font-600">{{count}}</div>
        <div class="font-600">{{title}}</div>
        <div class="font-600">$ {{price}}</div>
      </div>
    </b-col>
</template>

<script>
export default {
  props: {
    title: String,
    count: Number,
    price: Number,
    selected: Boolean,
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/argon.scss';
@import "@/assets/sass/lbd/mixins/_layout.scss";

.app-border.text-item {
  border-width: 2px;
  transition: all .35s linear;

  &.selected {
    border-color: $primary;
  }
}
</style>
