<template>
  <div>
    <card bodyClasses="px-0" >
    <template slot="header">
      <h3 class="mb-0">Top up your messages</h3>
    </template>
    <b-container fluid>
      <b-row>
        <template>
          <top-up-text-item
          v-for="(txt, index) in textPackagesPrices"
          :key="`text_package_${index}`"
          title="Texts"
          :count="txt.count"
          :price="txt.price"
          :selected="selected(txt)"
          @select="select(txt)"
          />
        </template>
      </b-row>
    </b-container>
    <div class="d-flex justify-content-end px-3 pt-2">
      <base-button type="primary" :disabled="disabledBtn" @click="textSelectionHandler">Purchase</base-button>
    </div>
    </card>
    <confirmation-modal
      ref="confirmationModal"
      header="Top up your messages"
      :question="confirmQuestion"
      confirmBtnText="Confirm Purchase"
      cancelBtnText="Cancel"
      @on-accept="purchaseText"
    />
  </div>
</template>
<script>

import {
  mapActions,
  mapGetters,
} from 'vuex';
import TopUpTextItem from './TopUpTextItem.vue';
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';
import {BUY_TEXT_PACKAGE_REQUEST} from '@/store/storeActions';

export default {
  components: {
    TopUpTextItem,
    ConfirmationModal,
  },
  data() {
    return {
      selectedPackage: null,
    };
  },
  computed: {
    ...mapGetters([
      'textPackagesPrices',
    ]),
    disabledBtn() {
      return !this.selectedPackage;
    },
    confirmQuestion() {
      return this.selectedPackage && `You will be charged $ ${this.selectedPackage.price}`;
    },
  },
  methods: {
    ...mapActions([
      BUY_TEXT_PACKAGE_REQUEST,
    ]),
    selected(txt) {
      return !!this.selectedPackage ?
      txt.count === this.selectedPackage.count && txt.price === this.selectedPackage.price : false;
    },
    select(txt) {
      this.selectedPackage = txt;
    },
    textSelectionHandler() {
      this.$refs.confirmationModal.openModal();
    },
    purchaseText() {
      this.BUY_TEXT_PACKAGE_REQUEST({
        textPackage: this.selectedPackage.count,
      });
    },
  },
};
</script>
<style scoped>
.seats {
  width: 80px;
}
.apply-button {
  margin-top: 32px;
}
</style>
